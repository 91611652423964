import { Button } from "@atoms/button/button";
import { Page } from "@atoms/layout/page";
import { useSetRecoilState } from "recoil";
import { SendOrderModal } from "../send-order-modal";
import { TableGridSwitch } from "@molecules/table/table-grid-switch";
import { Column } from "@molecules/table/table";
import { useState } from "react";
import { usePersistedState } from "@features/utils/hooks/use-local-storage";
import { Base, Info } from "@atoms/text";
import { SupplierOrderPrepLine } from "@features/supplier/types";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { SearchProductModalAtom } from "../search-product-modal";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "@features/routes";
import { BookRender } from "@atoms/book";

export const SupplierSendOrderLinePage = () => {
  const setSendOrderModal = useSetRecoilState(SendOrderModal);
  const { codeTypeProd } = useParams<{ codeTypeProd: string }>();
  const [orderLines, setOrderLines] = useState<SupplierOrderPrepLine[]>([]);
  const { selectedPrepHeader, getOrderPreparationLines } = useSuppliers();
  const setSearchProductModal = useSetRecoilState(SearchProductModalAtom);
  const navigate = useNavigate();
  const [gridMode, setGridMode] = usePersistedState(
    "user",
    "supplier-send-order-grid-mode",
    {
      activated: false,
    }
  );
  const handleCloseProductSearchModal = () => {
    setSearchProductModal({ open: false, order: undefined });
  };

  // const handleBrouillardModal = async (force = false) => {
  //   if (codeTypeProd && orderLines && orderLines.length > 0) {
  //     const res = await createOrderBrouillard(
  //       codeTypeProd,
  //       orderLines[0].codeLieuPourQui,
  //       orderLines[0].niv,
  //       selectedPrepHeader
  //     );
  //     if (res) {
  //       if (res.response === "OK") {
  //         await SupplierApiClient.processingOrderPrepHeader(
  //           codeTypeProd,
  //           orderLines[0].codeLieuPourQui,
  //           orderLines[0].niv,
  //           selectedPrepHeader,
  //           true
  //         );
  //         if (res.msg !== "") toast.success(res.msg);
  //         const resBrouillard = await getOrderBrouillard(
  //           codeTypeProd,
  //           orderLines[0].codeLieuPourQui,
  //           orderLines[0].niv,
  //           selectedPrepHeader
  //         );
  //         if (resBrouillard)
  //           setSendOrderModal({
  //             open: true,
  //             codeTypeProd: codeTypeProd,
  //             codeLieuPourQui: orderLines[0].codeLieuPourQui,
  //             niv: orderLines[0].niv,
  //             orderBrouillard: resBrouillard,
  //           });
  //       }
  //       if (res && res.response === "?") {
  //         setConfirmModal({
  //           theme: "warning",
  //           icon: () => (
  //             <FaExclamationTriangle className="text-red-600 h-6 w-6" />
  //           ),
  //           open: true,
  //           title: "",
  //           message: res.msg,
  //           onAccept: async () => {
  //             await SupplierApiClient.processingOrderPrepHeader(
  //               codeTypeProd,
  //               orderLines[0].codeLieuPourQui,
  //               orderLines[0].niv,
  //               selectedPrepHeader,
  //               false
  //             );
  //             handleBrouillardModal();
  //           },
  //         });
  //       }
  //     }
  //   }
  // };

  useControlledEffect(() => {
    const messageHandler = async (event: MessageEvent) => {
      if (event.data.action === "closeModal") {
        handleCloseProductSearchModal();
        if (event.data.product) {
          const resLine = await getOrderPreparationLines(
            codeTypeProd || "LIVR",
            selectedPrepHeader.codeFourn,
            selectedPrepHeader.codeLieuLivr,
            selectedPrepHeader.panie,
            selectedPrepHeader.codeModeExpe
          );
          if (resLine) setOrderLines(resLine);
          window.location.reload();

          //createOrder(product as ProductType)
        }
      }
    };

    window.addEventListener("message", messageHandler);

    // Nettoyage de l'événement lors du démontage du composant
    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, []);

  const orderColumns: Column<SupplierOrderPrepLine>[] = [
    {
      title: "Article",
      id: "",
      render: (p) => (
        <div
          className="flex flex-row items-center space-x-4 cursor-pointer"
          onClick={() =>
            navigate(
              ROUTES.Product.replace(/:ean/, p.ean13).replace(
                /:type/,
                p.tooltip.modeGest
              )
            )
          }
        >
          <div className="w-10 print:hidden">
            <BookRender src={p.imageURL} productType={p.tooltip.modeGest} />
          </div>
          <div className="flex flex-col">
            <Base
              className="block overflow-hidden whitespace-nowrap text-ellipsis print:whitespace-normal max-w-xs"
              data-tooltip={p.desig}
            >
              {p.desig}
            </Base>
            <Info>{p.ean13}</Info>
          </div>
        </div>
      ),
    },
    {
      title: "Rayon",
      id: "codeRayon",
      render: (p) => <Base>{p.codeRayon}</Base>,
    },
    {
      title: "Éditeur",
      id: "codeEdit",
      render: (p) => <Base>{p.codeEdit}</Base>,
    },
    {
      title: "PU TTC",
      id: "raisSocial",
      render: (p) => <Base>{p.prixBase}</Base>,
    },
    {
      title: "Orig.",
      id: "orig",
      render: (p) => <Base>{p.orig}</Base>,
    },
    {
      title: "Fournisseur",
      id: "codeFounr",
      render: (p) => <Base>{p.codeFourn}</Base>,
    },
    {
      title: "Exp.",
      id: "codeModeExpe",
      render: (p) => <Base>{p.codeModeExpe}</Base>,
    },
    {
      title: "Opé.",
      id: "codeOpe",
      render: (p) => <Base>{p.codeOpe}</Base>,
    },
    {
      title: "Panier",
      id: "panie",
      render: (p) => <Base>{p.panie}</Base>,
    },
    {
      title: "Quantité",
      id: "qte",
      render: (p) => <Base>{p.qte}</Base>,
    },
    {
      title: "Dispo. vente",
      id: "qteDispoVente",
      render: (p) => <Base>{p.qteDispoVente}</Base>,
    },
  ];

  return (
    <Page>
      <>
        <div className="flex justify-end gap-2 ">
          <Button
            onClick={() => {
              if (codeTypeProd && orderLines)
                setSendOrderModal({
                  open: true,
                  codeTypeProd: codeTypeProd,
                  codeLieuPourQui: orderLines[0].codeLieuPourQui,
                  niv: orderLines[0].niv,
                  orderBrouillard: undefined,
                  orderHeaderList: [selectedPrepHeader],
                });
            }}
            // onClick={async () => {
            //   if (codeTypeProd && orderLines && orderLines.length > 0) {
            //     const res = await createOrderBrouillard(
            //       codeTypeProd,
            //       orderLines[0].codeLieuPourQui,
            //       orderLines[0].niv,
            //       selectedPrepHeader
            //     );
            //     if (res) {
            //       if (res.response === "OK") {
            //         const resBrouillard = await getOrderBrouillard(
            //           codeTypeProd,
            //           orderLines[0].codeLieuPourQui,
            //           orderLines[0].niv,
            //           selectedPrepHeader
            //         );
            //         if (resBrouillard) setSendOrderModal({ open: true });
            //       }
            //       if (res && res.response === "?") {
            //         setConfirmModal({
            //           theme: "warning",
            //           icon: () => (
            //             <FaExclamationTriangle className="text-red-600 h-6 w-6" />
            //           ),
            //           open: true,
            //           title: "",
            //           message: res.msg,
            //           onAccept: async () => {
            //             await SupplierApiClient.processingOrderPrepHeader(
            //               codeTypeProd,
            //               orderLines[0].codeLieuPourQui,
            //               orderLines[0].niv,
            //               selectedPrepHeader,
            //               false
            //             );
            //             await createOrderBrouillard(
            //               codeTypeProd,
            //               orderLines[0].codeLieuPourQui,
            //               orderLines[0].niv,
            //               selectedPrepHeader,
            //               true
            //             );
            //           },
            //         });
            //       }
            //     }
            //   }
            // }}
          >
            Envoi de commande
          </Button>
          <Button
            theme="outlined"
            icon={(p) => <PlusCircleIcon {...p} />}
            onClick={() => setSearchProductModal({ open: true })}
          >
            Ajouter un article
          </Button>
        </div>
        <TableGridSwitch
          name="cdeFournLigne"
          data={orderLines}
          total={orderLines.length}
          showPagination={"totalOnly"}
          onChangeMode={(v) => setGridMode({ activated: v })}
          gridMode={gridMode.activated}
          tableColumns={orderColumns}
          onRequestData={async () => {
            const res = await getOrderPreparationLines(
              codeTypeProd || "LIVR",
              selectedPrepHeader.codeFourn,
              selectedPrepHeader.codeLieuLivr,
              selectedPrepHeader.panie,
              selectedPrepHeader.codeModeExpe
            );
            if (res) setOrderLines(res);
            else setOrderLines([]);
          }}
          renderGrid={() => <> </>}
        />
      </>
    </Page>
  );
};
