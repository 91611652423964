import { Button } from "@atoms/button/button";
import { Input } from "@atoms/input/input-text";
import { Page } from "@atoms/layout/page";
import ModuleBox from "@components/module";
import { Table } from "@molecules/table";
import { useActions } from "@features/actions";
import { applySearchFilter } from "@features/utils/format/strings";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { modules } from "../../../features/modules";
import { ShortcutMapping } from "@features/settings/shortcuts/mapping";
import { useShopLocations } from "@features/general/auth/state/use-store-location";

export const HomePage = () => {
  const { t } = useTranslation();
  const { functionsAuthorization } = useShopLocations();
  const [query, setQuery] = useState("");
  const navigate = useNavigate();
  const actions = useActions();

  const filteredActions = actions.filter(
    (a) =>
      query &&
      (applySearchFilter(query, a.name) || applySearchFilter(query, a.keywords))
  );
  const filteredModules = modules
    .filter((a) => applySearchFilter(query, a.name))
    .filter((m) => !m.hideModule);

  return (
    <Page>
      <div className="my-6 md:my-16 flex justify-center items-center">
        <img
          src="/medias/logo.png"
          alt="logo"
          className="h-24 block dark:hidden"
        />
        <img
          src="/medias/logo-dark.png"
          alt="logo"
          className="h-24 dark:block hidden"
        />
      </div>

      <form
        autoComplete="off"
        className="my-12 md:my-16 flex flex-col justify-center items-center"
      >
        <Input
          autoComplete="off"
          size="lg"
          className="w-full max-w-2xl z-10"
          onChange={(e) => setQuery(e.target.value)}
          placeholder={t("home.search.placeholder")}
          shortcut={[ShortcutMapping.home.search]}
        />
        <Button
          onClick={() => {
            if (filteredActions.length > 0) {
              filteredActions[0].action();
            } else if (filteredModules.length > 0) {
              navigate(filteredModules[0].route);
            }
          }}
          shortcut={["enter"]}
          className="hidden"
        >
          -
        </Button>

        {!!filteredActions?.length && (
          <Table
            className="w-full max-w-2xl -mt-px"
            data={filteredActions}
            cellClassName={(row) =>
              row?.action === filteredActions[0]?.action ? "bg-blue-50" : ""
            }
            showPagination={false}
            onClick={(row) => row.action()}
            columns={[
              {
                render: (row) => row.name,
              },
            ]}
          />
        )}
      </form>

      <div
        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-4 m-auto"
        style={{ maxWidth: 1400 }}
      >
        {filteredModules.map((module, index) => {
          let unauthorized = false;
          if (module.functionHabilKey && functionsAuthorization) {
            unauthorized = Object.keys(functionsAuthorization)
              .filter((k) => k.includes(module.functionHabilKey || ""))
              .map((k) => functionsAuthorization[k].query)
              .some((v) => v === false);
          }
          return (
            <div
              key={index}
              className={`flex justify-center items-center w-full ${
                module.disabled || unauthorized ? "opacity-50" : ""
              }`}
            >
              <ModuleBox
                color={module.color}
                disabled={module.disabled || unauthorized}
                logo={module.logo}
                name={module.name}
                subtTitle={module.subTitle}
                route={module.route}
              />
            </div>
          );
        })}
      </div>
    </Page>
  );
};
