import { useRecoilState } from "recoil";
import { SupplierApiClient } from "../api-client/api-client";
import {
  Bl,
  CommandType,
  OrderPrepBrouillard,
  ReturnType,
  SupplierOrder,
  SupplierOrderPrepHeader,
  SupplierOrderPrepLine,
  SupplierOrderRequestFilter,
} from "../types";
import { LoadingState } from "@features/utils/store/loading-state-atom";
import { CommonPagination, CommonPaginationRequest } from "@features/types";
import {
  SelectedFournCde,
  SelectedSupplierPrepHeader,
  SupplierOrderList,
  SupplierReasLines,
} from "./store";

export const useSuppliers = () => {
  const [loading, setLoading] = useRecoilState(
    LoadingState(["useOrder", false])
  );

  const [selectedFournCde, setSelectedFournCde] =
    useRecoilState(SelectedFournCde);
  const [selectedPrepHeader, setSelectedPrepHeader] = useRecoilState(
    SelectedSupplierPrepHeader
  );
  const [reassortLines, setReassortLines] = useRecoilState(SupplierReasLines);
  const [supplierOrderList, setSupplierOrderList] =
    useRecoilState(SupplierOrderList);
  const getMotif = async () => {
    return await SupplierApiClient.getMotifRefus();
  };

  const getModesExpedition = async () => {
    return await SupplierApiClient.getModesExpedition();
  };

  const getSupplierDocument = async (
    id: string,
    documentType: "brouiCde" | "cde",
    modeGest: string,
    codeTypeProd: string,
    codeLieu: string,
    centr: boolean
  ) => {
    setLoading(true);
    try {
      const result = await SupplierApiClient.getSupplierDocument(
        id,
        documentType,
        modeGest,
        codeTypeProd,
        codeLieu,
        centr
      );
      setLoading(false);
      return result;
    } catch {
      setLoading(false);
    }
  };

  const getFournBl = async (
    codeFourn?: string,
    numBL?: string,
    recepOuver?: boolean,
    recepTerm?: boolean
  ) => {
    return await SupplierApiClient.getFournBl(
      codeFourn,
      numBL,
      recepOuver,
      recepTerm
    );
  };

  const getFournBlPackages = async (bl: Bl) => {
    return await SupplierApiClient.getFournBlPackages(bl);
  };

  const getFournBlLines = async (
    codeFourn?: string,
    numBL?: string,
    numColis?: string,
    idColis?: string,
    livrNbRef?: number,
    livrNb?: number
  ) => {
    return await SupplierApiClient.getFournBlLines(
      codeFourn,
      numBL,
      numColis,
      idColis,
      livrNbRef,
      livrNb
    );
  };

  const getFournOrders = async (
    filters: SupplierOrderRequestFilter,
    pagination?: CommonPaginationRequest
  ) => {
    return await SupplierApiClient.getFournOrders(filters, pagination);
  };

  const getFournOrderLines = async (numCde: string) => {
    return await SupplierApiClient.getFournOrderLines(numCde);
  };

  const changeSupplierOrderList = (
    newOrderList: SupplierOrder[],
    pagination?: CommonPagination
  ) => {
    setSupplierOrderList({
      pagination: pagination ? pagination : supplierOrderList.pagination,
      items: newOrderList,
    });
  };

  const createRetour = async (retour: ReturnType, codeLieu: string) => {
    setLoading(true);
    const res = await SupplierApiClient.createRetour(retour, codeLieu);
    setLoading(false);
    return res;
  };

  const createOrder = async (order: CommandType, codeLieu: string) => {
    try {
      setLoading(true);
      const res = await SupplierApiClient.createOrder(order, codeLieu);
      setLoading(false);
      return res;
    } catch {
      setLoading(false);
    }
  };

  const getReassortLines = async (
    modeGestion: string,
    codeTypeProduct?: string,
    pagination?: CommonPaginationRequest,
    filters?: any
  ) => {
    setLoading(true);
    try {
      const result = await SupplierApiClient.getReassortLines(
        modeGestion,
        codeTypeProduct,
        pagination,
        filters
      );
      setLoading(false);
      return result;
    } catch {
      setLoading(false);
    }
  };

  const updateReassortLine = async (
    id: string,
    ean: string,
    qte: number = 0,
    panie: string = "",
    codeEtat: string,
    logi: string
  ) => {
    setLoading(true);
    try {
      const result = await SupplierApiClient.updateReassortLine(
        id,
        ean,
        qte,
        panie,
        codeEtat,
        logi
      );
      setLoading(false);
      return result;
    } catch {
      setLoading(false);
      return false;
    }
  };

  const getOrderPreparationHeaders = async (
    codeTypeProd?: string,
    pourQui?: string,
    niv?: boolean
  ) => {
    setLoading(true);
    try {
      const result = await SupplierApiClient.getOrderPreparationHeader(
        codeTypeProd,
        pourQui,
        niv
      );
      setLoading(false);
      return result;
    } catch {
      setLoading(false);
    }
  };

  const updateOrderPreparationLine = async (
    orderLine: SupplierOrderPrepLine,
    codeModeExpe: string,
    panie: string,
    qte: number
  ) => {
    try {
      setLoading(true);
      await SupplierApiClient.updateOrderPreparationLine(
        orderLine,
        codeModeExpe,
        panie,
        qte
      );
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const updateOrderPreparationLineGrp = async (
    orderLine: SupplierOrderPrepLine,
    codeModeExpe: string,
    panie: string
  ) => {
    try {
      setLoading(true);
      await SupplierApiClient.updateOrderPreparationLineGrp(
        orderLine,
        codeModeExpe,
        panie
      );
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const getOrderPreparationLines = async (
    codeTypeProd: string,
    codeFourn: string,
    codeLieuLivr: string,
    panie?: string,
    codeModeExpe?: string,
    pourQui?: string,
    niv?: boolean
  ) => {
    setLoading(true);
    try {
      const result = await SupplierApiClient.getOrderPreparationLines(
        codeTypeProd,
        pourQui,
        niv,
        codeFourn,
        panie,
        codeModeExpe,
        codeLieuLivr
      );
      setLoading(false);
      return result;
    } catch {
      setLoading(false);
    }
  };

  const createOrderBrouillard = async (
    codeTypeProd: string,
    codeLieuPourQui: string,
    niv: boolean,
    order: SupplierOrderPrepHeader,
    forceReturn: boolean = false
  ) => {
    setLoading(true);
    try {
      const res = await SupplierApiClient.createOrderBrouillard(
        codeTypeProd,
        codeLieuPourQui,
        niv,
        order,
        forceReturn
      );
      setLoading(false);
      return res;
    } catch {
      setLoading(false);
    }
  };

  const getOrderBrouillard = async (
    codeTypeProd: string,
    codeLieuPourQui: string,
    niv: boolean,
    order: SupplierOrderPrepHeader
  ) => {
    setLoading(true);
    try {
      const res = await SupplierApiClient.getOrderBrouillard(
        codeTypeProd,
        codeLieuPourQui,
        niv,
        order
      );
      setLoading(false);
      return res;
    } catch {
      setLoading(false);
    }
  };

  const sendFournOrder = async (
    codeTypeProd: string,
    codeLieuPourQui: string,
    niv: boolean,
    order: SupplierOrderPrepHeader,
    brouil: OrderPrepBrouillard
  ) => {
    setLoading(true);
    try {
      const res = await SupplierApiClient.sendFournOrder(
        codeTypeProd,
        codeLieuPourQui,
        niv,
        order,
        brouil
      );
      setLoading(false);
      return res;
    } catch {
      setLoading(false);
      return null;
    }
  };

  const resetLinePrep = async (
    modeGest: string,
    codeTypeProd: string,
    codeLieuPourQui: string,
    niv: boolean,
    order: SupplierOrderPrepHeader
  ) => {
    setLoading(true);
    try {
      const res = await SupplierApiClient.resetLinePrep(
        modeGest,
        codeTypeProd,
        codeLieuPourQui,
        niv,
        order
      );
      setLoading(false);
      return res;
    } catch {
      setLoading(false);
    }
  };

  const changeSelectedFournCde = (newOrder: SupplierOrder) => {
    setSelectedFournCde(newOrder);
  };

  const changeSelectedPrepHeader = (newHeader: SupplierOrderPrepHeader) => {
    setSelectedPrepHeader(newHeader);
  };

  return {
    loading,
    reassortLines,
    supplierOrderList,
    selectedFournCde,
    selectedPrepHeader,
    setReassortLines,
    changeSupplierOrderList,
    getFournBl,
    getFournBlPackages,
    getFournBlLines,
    getFournOrders,
    getSupplierDocument,
    getFournOrderLines,
    getMotif,
    getModesExpedition,
    createRetour,
    createOrder,
    getReassortLines,
    updateReassortLine,
    updateOrderPreparationLine,
    updateOrderPreparationLineGrp,
    getOrderPreparationHeaders,
    getOrderPreparationLines,
    changeSelectedFournCde,
    changeSelectedPrepHeader,
    createOrderBrouillard,
    getOrderBrouillard,
    resetLinePrep,
    sendFournOrder,
  };
};
