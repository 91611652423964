import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import { Checkbox } from "@atoms/input/input-checkbox";
import { Frame } from "@atoms/layout/frame";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { InfoSmallBold } from "@atoms/text";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { CommonApiClient } from "@features/general/common/api-client/api-client";
import { ROUTES } from "@features/routes";
import { SupplierApiClient } from "@features/supplier/api-client/api-client";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import {
  OrderPrepBrouillard,
  SupplierOrderPrepHeader,
} from "@features/supplier/types";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { CheckCircleIcon, PrinterIcon } from "@heroicons/react/outline";
import { CommonConfirmModalAtom } from "@molecules/confirm-modal";
import { Form } from "@molecules/form";
import { SearchFormFieldType } from "@molecules/form/types";
import { PdfModal } from "@molecules/pdf-viewer/pdf-viewer-modal";
import { useState } from "react";
import toast from "react-hot-toast";
import { FaExclamationTriangle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { atom, useRecoilState, useSetRecoilState } from "recoil";

type SendOrderModalType = {
  open: boolean;
  codeTypeProd: string;
  codeLieuPourQui: string;
  niv: boolean;
  orderBrouillard?: OrderPrepBrouillard;
  orderHeaderList?: SupplierOrderPrepHeader[];
  mode?: string;
};

export const SendOrderModal = atom<SendOrderModalType>({
  key: "SendOrderModalAtom",
  default: { open: false, codeTypeProd: "", codeLieuPourQui: "", niv: false },
});

export const SendSupplierOrderModal = () => {
  const [sendOrderModal, setSendOrderModal] = useRecoilState(SendOrderModal);
  const [confirmModal, setConfirmModal] = useRecoilState(
    CommonConfirmModalAtom
  );
  const [waitRank, setWaitRank] = useState(0);

  const setPdfModal = useSetRecoilState(PdfModal);
  const [finish, setFinish] = useState(false);
  const [brouiList, setBrouiList] = useState<OrderPrepBrouillard[]>([]);
  const [numCde, setNumCde] = useState("");
  const { current } = useShopLocations();
  const navigate = useNavigate();
  const {
    selectedPrepHeader,
    createOrderBrouillard,
    getOrderBrouillard,
    getModesExpedition,
    sendFournOrder,
    getSupplierDocument,
  } = useSuppliers();
  //const [valid, setValid] = useState(false);
  //const [edi, setEdi] = useState(false);
  const [modesExpedition, setModesExpedition] = useState<
    { value: string; label: string }[] | null
  >(null);
  const [tauxTypes, setTauxTypes] = useState<
    { value: string; label: string }[] | null
  >(null);
  const [grpLieu, setGrpLieu] = useState<
    { value: string; label: string }[] | null
  >(null);
  const [typeAchat, setTypeAchat] = useState<
    { value: string; label: string }[] | null
  >(null);
  const [servEdi, setServEdi] = useState<
    { value: string; label: string }[] | null
  >(null);
  const [transmissions, setTransmissions] = useState<
    { value: string; label: string }[] | null
  >(null);
  const [forms, setForms] = useState<{ value: string; label: string }[] | null>(
    null
  );

  const handleClose = async () => {
    if (!confirmModal.open) {
      if (
        sendOrderModal.codeTypeProd !== "" &&
        sendOrderModal.orderBrouillard &&
        sendOrderModal.orderHeaderList &&
        !finish // temp
      )
        await SupplierApiClient.processingOrderPrepHeader(
          sendOrderModal.orderHeaderList[waitRank].codeTypeProd,
          sendOrderModal.orderHeaderList[waitRank].codeLieuPourQui,
          sendOrderModal.orderHeaderList[waitRank].niv,
          sendOrderModal.orderHeaderList[waitRank], // temp
          false
        );
      //setValid(false);
      setWaitRank(0);
      setFinish(false);
      setBrouiList([]);
      setSendOrderModal({
        open: false,
        codeTypeProd: "",
        codeLieuPourQui: "",
        niv: false,
        orderBrouillard: undefined,
        orderHeaderList: undefined,
      });
      if (finish) navigate(ROUTES.SupplierSendOrderPage);
    }
  };

  const handleBrouillardModal = async (
    force = false,
    prepHeader: SupplierOrderPrepHeader
  ) => {
    if (sendOrderModal) {
      const res = await createOrderBrouillard(
        sendOrderModal.codeTypeProd,
        sendOrderModal.codeLieuPourQui,
        sendOrderModal.niv,
        prepHeader,
        force
      );
      if (res) {
        if (res.response === "OK") {
          await SupplierApiClient.processingOrderPrepHeader(
            sendOrderModal.codeTypeProd,
            sendOrderModal.codeLieuPourQui,
            sendOrderModal.niv,
            prepHeader,
            true
          );
          if (res.msg !== "") toast.success(res.msg);
          const resBrouillard = await getOrderBrouillard(
            sendOrderModal.codeTypeProd,
            sendOrderModal.codeLieuPourQui,
            sendOrderModal.niv,
            prepHeader
          );
          if (resBrouillard)
            setSendOrderModal({
              ...sendOrderModal,
              codeTypeProd: sendOrderModal.codeTypeProd,
              codeLieuPourQui: sendOrderModal.codeLieuPourQui,
              niv: sendOrderModal.niv,
              orderBrouillard: resBrouillard,
            });
        }
        if (res && res.response === "?") {
          setConfirmModal({
            theme: "warning",
            icon: () => (
              <FaExclamationTriangle className="text-red-600 h-6 w-6" />
            ),
            open: true,
            title: "",
            message: res.msg,
            onAccept: async () => {
              await SupplierApiClient.processingOrderPrepHeader(
                sendOrderModal.codeTypeProd,
                sendOrderModal.codeLieuPourQui,
                sendOrderModal.niv,
                prepHeader,
                false
              );
              handleBrouillardModal(true, prepHeader);
            },
          });
        }
      }
    }
  };

  useControlledEffect(() => {
    const getModeExp = async () => {
      setModesExpedition([
        { label: "<Aucun>", value: "" },
        ...(await getModesExpedition()),
      ]);
    };

    const getTauxTypes = async () => {
      const tauxList = await CommonApiClient.getTaux(
        sendOrderModal.codeTypeProd
      );
      setTauxTypes([
        { label: "<Aucun>", value: "" },
        ...tauxList.map((t) => {
          return { label: t.lib, value: t.code };
        }),
      ]);
    };

    const getGrpLieu = async () => {
      setGrpLieu([
        { label: "<Aucun>", value: "" },
        ...(await CommonApiClient.getGroupeLieu()),
      ]);
    };

    const getTypeAchat = async () => {
      setTypeAchat([
        { label: "<Aucun>", value: "" },
        ...(await CommonApiClient.getTypeAchat()).map((el) => {
          return { label: el.libTypeAchat, value: el.codeTypeAchat };
        }),
      ]);
    };

    const getTransmissions = async () => {
      setTransmissions([
        { label: "<Aucun>", value: "" },
        ...(await CommonApiClient.getTransmissions()),
      ]);
    };

    const getServsEdi = async () => {
      setServEdi([
        { label: "<Aucun>", value: "" },
        ...(await CommonApiClient.getServEdis()),
      ]);
    };

    const getForms = async () => {
      setForms([
        { label: "<Aucun>", value: "" },
        ...(await CommonApiClient.getForms()),
      ]);
    };

    if (sendOrderModal.open) {
      if (modesExpedition?.length === 0 || !modesExpedition) getModeExp();
      if (tauxTypes?.length === 0 || !tauxTypes) getTauxTypes();
      if (grpLieu?.length === 0 || !grpLieu) getGrpLieu();
      if (typeAchat?.length === 0 || !typeAchat) getTypeAchat();
      if (transmissions?.length === 0 || !transmissions) getTransmissions();
      if (servEdi?.length === 0 || !servEdi) getServsEdi();
      if (forms?.length === 0 || !forms) getForms();
    }
  }, [sendOrderModal.open]);

  useControlledEffect(() => {
    const getBrouillard = async (prepHeader: SupplierOrderPrepHeader) => {
      handleBrouillardModal(false, prepHeader);
    };
    if (
      sendOrderModal.open &&
      sendOrderModal.orderHeaderList &&
      sendOrderModal.orderHeaderList.length > 0
    ) {
      getBrouillard(sendOrderModal.orderHeaderList[waitRank]);
    }
    if (sendOrderModal.mode && sendOrderModal.mode === "envFourn") {
    }
  }, [sendOrderModal.open, waitRank]);

  const mainFormPart: SearchFormFieldType[] = [
    {
      key: "codeFourn",
      label: "Fournisseur",
      type: "text",
      alwaysVisible: true,
      edit: true,
      disabled: true,
    },
    {
      key: "codeLieuLivr",
      label: "Livré à",
      type: "text",
      alwaysVisible: true,
      edit: true,
      disabled: true,
    },
    {
      key: "codeModeExpe",
      label: "Exp.",
      type: "select",
      alwaysVisible: true,
      edit: true,
      options: modesExpedition || [],
    },
    {
      key: "panie",
      label: "Panier",
      type: "text",
      alwaysVisible: true,
      edit: true,
      disabled: true,
    },
    {
      key: "refer",
      label: "Référ. opé",
      type: "text",
      alwaysVisible: true,
      edit: true,
    },
    {
      key: "codeTypeTaux",
      label: "TAUM",
      type: "select",
      alwaysVisible: true,
      edit: true,
      options: tauxTypes || [],
    },
    {
      key: "codeTypeAchat",
      label: "Type achat",
      type: "select",
      alwaysVisible: true,
      edit: true,
      options: typeAchat || [],
    },
    {
      key: "dateLivPrev",
      label: "Livraison prév. ",
      type: "date",
      alwaysVisible: true,
      edit: true,
    },
    {
      key: "referOpe",
      label: "Référence",
      type: "text",
      alwaysVisible: true,
      edit: true,
    },
    // {
    //   key: "edi",
    //   label: "EDI",
    //   type: "boolean",
    //   alwaysVisible: true,
    //   edit: true,
    // },
    {
      key: "comm",
      label: "Commentaire",
      type: "multiline",
      alwaysVisible: true,
      edit: true,
    },
  ];

  const ediFormPart: SearchFormFieldType[] = [
    {
      key: "codeFourn",
      label: "Fournisseur",
      type: "text",
      alwaysVisible: true,
    },
    {
      key: "codeLieuParQui",
      label: "Lieu émission",
      type: "select",
      alwaysVisible: true,
      edit: true,
    },
    {
      key: "codeGrp",
      label: "Code groupe",
      type: "select",
      alwaysVisible: true,
      edit: true,
      options: grpLieu || [],
    },
    {
      key: "statu", // --> Comment le mapper ??
      label: "Statut",
      type: "select",
      alwaysVisible: true,
      edit: true,
      options: [
        { label: "À envoyer", value: "jsp1" },
        { label: "Option 2", value: "jsp2" },
      ],
    },
    {
      key: "nomServEdi",
      label: "Serveur",
      type: "text",
      alwaysVisible: true,
      edit: true,
      options: servEdi || [],
    },
    {
      key: "modeTransCde",
      label: "Transmission",
      type: "text",
      alwaysVisible: true,
      edit: true,
      options: transmissions || [],
    },
    {
      key: "codeForm",
      label: "Format",
      type: "select",
      alwaysVisible: true,
      edit: true,
      options: forms || [],
    },
    {
      key: "fax",
      label: "Fax",
      type: "text",
      alwaysVisible: true,
      edit: true,
      disabled: true,
    },
    {
      key: "addrMail",
      label: "e-mail",
      type: "text",
      alwaysVisible: true,
      edit: true,
    },
    {
      key: "gencodFourn",
      label: "GENCOD fourn.",
      type: "text",
      alwaysVisible: true,
      edit: true,
      disabled: true,
    },
    {
      key: "gencodEmis",
      label: "GENCOD emis.",
      type: "text",
      alwaysVisible: true,
      edit: true,
      disabled: true,
    },
    {
      key: "gencodeLivr",
      label: "GENCOD livré.",
      type: "text",
      alwaysVisible: true,
      edit: true,
      disabled: true,
    },
  ];

  return (
    <Modal
      className="sm:max-w-[900px]"
      open={sendOrderModal.open}
      onClose={async () => await handleClose()}
      loading={
        !modesExpedition || !tauxTypes || !grpLieu || !transmissions || !forms
      }
    >
      <ModalContent title={"Pramètres d'envoi de commande(s)"}>
        {sendOrderModal.orderBrouillard &&
          modesExpedition &&
          tauxTypes &&
          typeAchat && (
            <div className="flex flex-col gap-4 md:gap-2">
              <Form
                readonly={true} // Temporaire --> évoluera ultérieurement
                nbColumns={3}
                gapValue={4}
                fields={mainFormPart}
                value={sendOrderModal.orderBrouillard}
                onChange={(newValue) => {
                  setSendOrderModal({
                    ...sendOrderModal,
                    orderBrouillard: {
                      ...sendOrderModal,
                      ...(newValue as OrderPrepBrouillard),
                    },
                  });
                }}
              />
              <Checkbox
                disabled={true}
                value={sendOrderModal.orderBrouillard.edi} //onChange={() => setEdi(!edi)}
                label="EDI"
              />

              {sendOrderModal.orderBrouillard.edi && transmissions && forms && (
                <Frame>
                  <Form
                    readonly={true} // Temporaire --> évoluera ultérieurement
                    nbColumns={3}
                    gapValue={4}
                    fields={ediFormPart}
                    value={sendOrderModal.orderBrouillard}
                    onChange={(newValue) => {
                      setSendOrderModal({
                        ...sendOrderModal,
                        orderBrouillard: {
                          ...sendOrderModal,
                          ...(newValue as OrderPrepBrouillard),
                        },
                      });
                    }}
                  />
                </Frame>
              )}
              {/* {!valid && (
              <div className="w-full flex justify-between mt-4">
                <Button
                  theme="danger-outlined"
                  onClick={() => handleClose()}
                  icon={(p) => <XIcon {...p} />}
                >
                  Retour
                </Button>
               
                <Button
                  theme="secondary"
                  onClick={() => {
                    toast("Fonctionnalité bientôt disponible !");
                    setValid(true);
                  }}
                  icon={(p) => <SaveIcon {...p} />}
                >
                  Valider paramètres
                </Button>
              </div>
            )} */}
              <div className="w-full flex justify-end gap-4 relative">
                <Button
                  theme="secondary"
                  onClick={async () => {
                    if (sendOrderModal.orderBrouillard) {
                      const shopItemInfo = current?.items?.find(
                        (el) => el.codeTypeProd === sendOrderModal.codeTypeProd
                      );
                      const res = await getSupplierDocument(
                        finish
                          ? numCde
                          : sendOrderModal.orderBrouillard.numBroui,
                        finish ? "cde" : "brouiCde",
                        shopItemInfo?.modeGest || "L",
                        sendOrderModal.codeTypeProd,
                        sendOrderModal.orderBrouillard.codeLieuParQui,
                        current?.centr || false
                      );
                      if (res) {
                        setPdfModal({
                          open: true,
                          pdfProps: {
                            blobFile: res,
                            iframeSize: {
                              width: "80%",
                              height: "90%",
                            },
                            iFrameClassname:
                              "w-full h-full flex justify-center items-center",
                          },
                        });
                      }
                    }
                  }}
                  icon={(p) => <PrinterIcon {...p} />}
                >
                  {finish ? "Imprimer bon de commande" : "Imprimer brouillard"}
                </Button>

                {!finish && (
                  <ButtonConfirm
                    id="send-order-btn"
                    className="justify-self-end"
                    theme="valid"
                    confirmTitle="Valider l'envoi"
                    confirmMessage="Souhaitez-vous confirmer l'envoi de cette commande ?"
                    confirmButtonText="Envoyer"
                    shortcut={["enter"]}
                    onClick={async (e) => {
                      console.log(brouiList);
                      if (sendOrderModal.orderBrouillard?.codeLieuParQui) {
                        const res = await sendFournOrder(
                          sendOrderModal.codeTypeProd,
                          sendOrderModal.codeLieuPourQui,
                          sendOrderModal.niv,
                          sendOrderModal.orderHeaderList
                            ? sendOrderModal.orderHeaderList[waitRank]
                            : selectedPrepHeader,
                          sendOrderModal.orderBrouillard
                        );
                        if (res) {
                          //Dispatch to update header lines in the background page
                          const customEvent = new CustomEvent("sendOrderEvent");
                          window.dispatchEvent(customEvent);
                          //--------
                          setNumCde(res.numCde);
                          toast.success(
                            "Commande fournisseur crée avec succès."
                          );
                          if (
                            sendOrderModal.orderHeaderList &&
                            sendOrderModal.mode === "global"
                          ) {
                            setWaitRank((previous) => {
                              if (
                                previous + 1 >
                                sendOrderModal.orderHeaderList!.length - 1
                              ) {
                                handleClose();
                                return previous;
                              }
                              return previous + 1;
                            });
                          } else {
                            setFinish(true);
                          }
                        }
                      }
                      const sendOrderBtn =
                        document.getElementById("send-order-btn");
                      if (sendOrderBtn)
                        (sendOrderBtn as HTMLButtonElement).blur();

                      //handleClose();
                    }}
                    icon={(p) => <CheckCircleIcon {...p} />}
                  >
                    {sendOrderModal.orderHeaderList &&
                    waitRank === sendOrderModal.orderHeaderList.length
                      ? "Fermer"
                      : "Envoi de commande(s)"}
                  </ButtonConfirm>
                )}
                {sendOrderModal.orderHeaderList &&
                  sendOrderModal.mode === "global" && (
                    <InfoSmallBold className="absolute bottom-0 left-3 z-60">{`${
                      waitRank + 1
                    }\\${
                      sendOrderModal.orderHeaderList?.length
                    }`}</InfoSmallBold>
                  )}
              </div>
            </div>
          )}
      </ModalContent>
    </Modal>
  );
};
