import { Base, BaseBold, Info } from "@atoms/text";
import { SupplierOrderPrepHeader } from "@features/supplier/types";

export default function SupplierOrderPrepHeaderCard({
  orderPrep,
}: {
  orderPrep: SupplierOrderPrepHeader;
}) {
  return (
    <div className="flex grow h-48 relative " onClick={() => {}}>
      <div className="h-full flex flex-col gap-2">
        <BaseBold>
          {orderPrep.raisSocial} (<Base>{orderPrep.codeFourn})</Base>
        </BaseBold>
        <Info>Panier: {orderPrep.panie}</Info>
        <Info>
          Franco de {orderPrep.francPort}{" "}
          {orderPrep.francoAtteint ? "atteint" : "non atteint"}
        </Info>
        <Info>Mode d'expédition: {orderPrep.codeModeExpe}</Info>
        <Info>Nombres d'articles: {orderPrep.nbrLigne}</Info>
      </div>
    </div>
  );
}
