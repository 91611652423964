import { Page } from "@atoms/layout/page";
import { TableGridSwitch } from "@molecules/table/table-grid-switch";
import { Column } from "@molecules/table/table";
import { useRef, useState } from "react";
import { usePersistedState } from "@features/utils/hooks/use-local-storage";
import { Base } from "@atoms/text";
import { SupplierOrderPrepHeader } from "@features/supplier/types";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import { Checkbox } from "@atoms/input/input-checkbox";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@features/routes";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import Tabs from "@atoms/tabs";
import SupplierOrderPrepHeaderCard from "@components/supplier/order-cards";
import { Button } from "@atoms/button/button";
import { useControlledEffect } from "@features/utils/hooks/use-controlled-effect";
import { useSetRecoilState } from "recoil";
import { SendOrderModal } from "../send-order-modal";

export const SupplierSendOrderPage = () => {
  const { getOrderPreparationHeaders, changeSelectedPrepHeader } =
    useSuppliers();
  const setSendOrderModal = useSetRecoilState(SendOrderModal);

  const { current } = useShopLocations();
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState<SupplierOrderPrepHeader[]>(
    []
  );
  const [sameFourn, setSameFourn] = useState(false);

  const [orderHeaders, setOrderHeaders] = useState<SupplierOrderPrepHeader[]>(
    []
  );

  const [tabIndex, setTabIndex] = usePersistedState<number | string>(
    "user",
    "fourn-envoi-cde-index",
    current && current.items
      ? current!.items.filter(
          (el) => el.modeGest === "L" || el.modeGest === "P"
        )[0].codeTypeProd
      : ""
  );

  const [gridMode, setGridMode] = usePersistedState(
    "user",
    "supplier-send-order-grid-mode",
    {
      activated: false,
    }
  );
  const codeTypeProdRef = useRef<string | number>(
    current && current.items
      ? current!.items.filter(
          (el) => el.modeGest === "L" || el.modeGest === "P"
        )[0].codeTypeProd
      : ""
  );

  useControlledEffect(() => {
    if (selectedRows.length > 0)
      setSameFourn(
        selectedRows.every((row) => row.codeFourn === selectedRows[0].codeFourn)
      );
  }, [selectedRows]);

  useControlledEffect(() => {
    const handleEvent = async (event: Event) => {
      setTabIndex((previous) => {
        const newCodeTypeProd = previous !== "" ? previous : tabIndex;
        codeTypeProdRef.current = newCodeTypeProd; // Met à jour la référence
        return previous;
      });
      const res = await getOrderPreparationHeaders(
        codeTypeProdRef.current.toString(),
        current?.codeLieu
      );
      if (res) setOrderHeaders(res);
    };

    window.addEventListener("sendOrderEvent", handleEvent);

    return () => {
      window.removeEventListener("sendOrderEvent", handleEvent);
    };
  }, []);

  const orderColumns: Column<SupplierOrderPrepHeader>[] = [
    {
      title: "Fournisseur",
      id: "codeFourn",
      render: (p) => <Base>{p.codeFourn}</Base>,
    },
    {
      title: "Raison-sociale",
      id: "raisSocial",
      render: (p) => <Base>{p.raisSocial}</Base>,
    },
    {
      title: "Panier",
      id: "panie",
      render: (p) => <Base>{p.panie}</Base>,
    },
    {
      title: "Mode exp.",
      id: "codeModeExpe",
      render: (p) => <Base>{p.codeModeExpe}</Base>,
    },
    {
      title: "Qte",
      id: "qte",
      render: (p) => <Base>{p.qte}</Base>,
    },
    {
      title: "Brut",
      id: "JSP",
      render: (p) => <Base>JSP</Base>,
    },
    {
      title: "Franco",
      //id: "francoAtteint",
      //type: "boolean",
      noSearch: true,
      render: (p) => (
        <div className="w-full flex gap-2">
          <Base>{p.francPort}</Base>
          <Checkbox value={p.francoAtteint} />
        </div>
      ),
    },
    {
      title: "Référ",
      id: "ref",
      render: (p) => <Base>{p.ref}</Base>,
    },
    {
      title: "Ligne",
      id: "nbrLigne",
      render: (p) => <Base>{p.nbrLigne}</Base>,
    },
    {
      title: "En envoi",
      id: "enCours",
      render: (p) => <Base>{p.enCours}</Base>,
    },
    {
      title: "Qte cli",
      id: "qteCli",
      render: (p) => <Base>{p.qteCli}</Base>,
    },
    {
      title: "Poids",
      id: "totPoids",
      render: (p) => <Base>{p.totPoids}</Base>,
    },
  ];

  return (
    <Page>
      <>
        <div className="flex w-full justify-end">
          <Button
            size="sm"
            disabled={selectedRows.length === 0}
            onClick={() => {
              setSendOrderModal({
                open: true,
                codeTypeProd: selectedRows[0].codeTypeProd,
                codeLieuPourQui: selectedRows[0].codeLieuPourQui,
                niv: selectedRows[0].niv,
                orderHeaderList: selectedRows,
                mode:
                  selectedRows.length > 1 && !sameFourn
                    ? "global"
                    : sameFourn
                    ? "envFourn"
                    : "",
              });
            }}
          >
            {selectedRows.length <= 1
              ? "Envoi unitaire"
              : selectedRows.length === orderHeaders.length
              ? "Envoi global"
              : sameFourn
              ? "Envoi par fournisseur"
              : "Envoi par selection multiple"}
          </Button>
        </div>

        <div className="flex flex-col grow items-center gap-6">
          <Tabs
            tabs={
              current?.items
                ? current?.items
                    ?.filter((el) => el.modeGest === "L" || el.modeGest === "P")
                    .map((el) => {
                      return { value: el.codeTypeProd, label: el.libTypeProd };
                    })
                : []
            }
            value={tabIndex}
            onChange={async (v) => {
              if (current && current.items) {
                setTabIndex(
                  current.items.find((el) => el.codeTypeProd === v)
                    ?.codeTypeProd || "P"
                );

                codeTypeProdRef.current =
                  current.items.find((el) => el.codeTypeProd === v)
                    ?.codeTypeProd || "P";
              }
            }}
          />
        </div>
        {/* <Button
          className="mt-4 md:mt-0"
          authorized={functionsAuthorization[`EnvCde-${tabIndex}`].actif}
          //onClick={() => setSendOrderModal({ open: true })}
        >
          Envoi commande
        </Button> */}
        <TableGridSwitch
          key={tabIndex}
          name="cdeFournHeader"
          data={orderHeaders}
          onChangeMode={(v) => setGridMode({ activated: v })}
          searchModeEnabled={true}
          gridMode={gridMode.activated}
          showPagination={"totalOnly"}
          tableColumns={orderColumns}
          total={orderHeaders.length}
          onClick={(e) => {
            changeSelectedPrepHeader(e);
            navigate(
              ROUTES.SupplierSendOrderLinePage.replace(
                /:codeTypeProd/,
                tabIndex.toString()
              )
            );
          }}
          onRequestData={async () => {
            const res = await getOrderPreparationHeaders(
              tabIndex.toString(),
              current?.codeLieu
            );
            if (res) setOrderHeaders(res);
            else setOrderHeaders([]);
          }}
          renderGrid={(el) => <SupplierOrderPrepHeaderCard orderPrep={el} />}
          rowIndex="find"
          onSelect={(e) => setSelectedRows(e)}
        />
      </>
    </Page>
  );
};
