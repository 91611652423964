import { CommonPagination } from "@features/types";
import {
  ReassortProductLine,
  SupplierOrder,
  SupplierOrderPrepHeader,
} from "../types";
import { atom } from "recoil";
import { persistAtom } from "@features/utils/hooks/use-local-storage";
import { Pagination } from "@molecules/table/table";

export const SupplierReasLines = atom<{
  pagination: CommonPagination;
  items: ReassortProductLine[];
}>({
  key: "ReasLines",
  default: {
    pagination: {
      pageNumber: 0,
      pageSize: 0,
      pageCount: 0,
      totalRows: 0,
      rowsReturned: 0,
    },
    items: [] as ReassortProductLine[],
  },
});

export const SupplierOrderList = atom<{
  pagination: CommonPagination;
  items: SupplierOrder[];
}>({
  key: "SupplierOrderList",
  default: {
    pagination: {
      pageNumber: 0,
      pageSize: 0,
      pageCount: 0,
      totalRows: 0,
      rowsReturned: 0,
    },
    items: [] as SupplierOrder[],
  },
});

export const SelectedFournCde = persistAtom<SupplierOrder>("user", {
  key: "SelectedFournCde",
  default: {} as SupplierOrder,
});

export const SelectedSupplierPrepHeader = persistAtom<SupplierOrderPrepHeader>(
  "user",
  {
    key: "SelectedSupplierPrepHeader",
    default: (() => {
      const user = {
        ...JSON.parse(localStorage.getItem("user.profile") || "null"),
      };
      try {
        return {
          ...JSON.parse(
            localStorage.getItem(
              `user-${user ? user.id : "UNKNOWN"}-SelectedSupplierPrepHeader`
            ) || "null"
          ),
        } as SupplierOrderPrepHeader;
      } catch (e) {
        return {} as SupplierOrderPrepHeader;
      }
    })(),
  }
);

export const ReasPagination = persistAtom<Pagination>("user", {
  key: "SupplierPagination",
  default: {
    total: 0,
    page: 1,
    perPage: 100,
    order: "DESC",
  } as Pagination,
});
