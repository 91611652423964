import { Info } from "@atoms/text";
import { useShopLocations } from "@features/general/auth/state/use-store-location";
import { SearchModeGestion } from "@features/products/state/store";
import { useProducts } from "@features/products/state/use-products";
import { convertISOStringsToShortFormat } from "@features/utils/format/dates";
import { SearchForm } from "@molecules/form";
import { SearchFormFieldType } from "@molecules/form/types";
import { t } from "i18next";
import { useRecoilState } from "recoil";

export const SearchFilters = ({
  advanced,
  value,
  onChange,
  onSearch,
}: {
  advanced: boolean;
  value: any;
  onChange: (filters: any) => void;
  onSearch: (truc?: string) => void;
}) => {
  const { getSuggestions, getAggregatedSuggestions, handleFamilySugg } =
    useProducts();

  //const [modeGestion, setModeGestion] = useRecoilState(SearchModeGestion);
  const [modeGestion, setModeGestion] = useRecoilState(SearchModeGestion);

  // useControlledEffect(() => {
  //   setModeGestion(
  //     current?.items!.find((typeProd) => typeProd.codeTypeProd === value.type)
  //       ?.modeGest || "all"
  //   );
  // }, [value.type]);

  const { current } = useShopLocations();

  const getFieldsTab = () => {
    if (current?.items) {
      //Temp fix for test version
      const temp = current.items.filter(
        (el) => el.modeGest !== "N" && el.modeGest !== "S"
      );
      const gestionList = [
        {
          label: "Tous les types",
          value: "all",
        },
      ].concat(
        temp.map((el) => {
          return {
            label: el.libTypeProd,
            value: el.codeTypeProd,
          };
        })
      );

      return gestionList;
    }
  };

  const BookFields = [
    {
      position: "type:book",
      key: "auteur",
      label: t("products.search.filters.livre.auteur.libelle"),
      placeholder: t("products.search.filters.livre.auteur.placeholder"),
      type: "searchselect",
      options: async (query) => {
        const result = await getSuggestions({
          Auteur: query,
          ModeGestion: modeGestion,
          CodeTypeProd: value.type,
          QueryExtendedAtBD: value.external_db,
        });
        return result;
      },
    } as SearchFormFieldType,
    {
      position: "type:book",
      key: "editeur",
      label: t("products.search.filters.livre.editeur.libelle"),
      placeholder: t("products.search.filters.livre.editeur.placeholder"),
      type: "searchselect",
      options: async (query) => {
        const result = (await getSuggestions({
          Editeur: query,
          ModeGestion: modeGestion,
          CodeTypeProd: value.type,
          QueryExtendedAtBD: value.external_db,
        })) as { label: string; value: string }[];
        return result;
      },
    } as SearchFormFieldType,
    {
      position: "type:book",
      key: "diffuseur",
      label: t("products.search.filters.livre.diffuseur.libelle"),
      placeholder: t("products.search.filters.livre.diffuseur.placeholder"),
      type: "searchselect",
      options: async (query) => {
        const result = (await getSuggestions({
          Diffuseur: query,
          ModeGestion: modeGestion,
          CodeTypeProd: value.type,
          QueryExtendedAtBD: value.external_db,
        })) as { label: string; value: string }[];
        return result;
      },
    } as SearchFormFieldType,
    {
      position: "type:book",
      key: "collection",
      label: t("products.search.filters.livre.collection.libelle"),
      placeholder: t("products.search.filters.livre.collection.placeholder"),
      type: "searchselect",
      options: async (query) => {
        const result = (await getSuggestions({
          Collection: query,
          ModeGestion: modeGestion,
          CodeTypeProd: value.type,
          QueryExtendedAtBD: value.external_db,
        })) as { label: string; value: string }[];
        return result;
      },
    } as SearchFormFieldType,
    {
      position: "type:book",
      key: "theme",
      label: t("products.search.filters.livre.theme.libelle"),
      placeholder: t("products.search.filters.livre.theme.placeholder"),
      type: "searchselect",
      options: async (query) => {
        const result = (await getSuggestions({
          Theme: query,
          ModeGestion: modeGestion,
          CodeTypeProd: value.type,
          QueryExtendedAtBD: value.external_db,
        })) as { label: string; value: string }[];
        return result;
      },
    } as SearchFormFieldType,

    {
      position: "type:book",
      key: "datePublicationApres",
      label: t("products.search.filters.livre.datePublicationApres"),
      type: "date",
    } as SearchFormFieldType,
    {
      position: "type:book",
      key: "datePublicationAvant",
      label: t("products.search.filters.livre.datePublicationAvant"),
      type: "date",
    } as SearchFormFieldType,
  ];

  const PaperFields = [
    {
      position: "type:paper",
      key: "marque",
      label: t("products.search.filters.paper.marque.libelle"),
      placeholder: t("products.search.filters.paper.marque.placeholder"),
      type: "searchselect",
      options: async (query) => {
        const result = (await getSuggestions({
          Marque: query,
          ModeGestion: modeGestion,
          CodeTypeProd: value.type,
          QueryExtendedAtBD: value.external_db,
        })) as { label: string; value: string }[];
        return result;
      },
    } as SearchFormFieldType,
    {
      position: "type:paper",
      key: "fabriquant",
      label: t("products.search.filters.paper.fabricant.libelle"),
      placeholder: t("products.search.filters.paper.fabricant.placeholder"),
      type: "searchselect",
      options: async (query) => {
        const result = (await getSuggestions({
          Fabriquant: query,
          ModeGestion: modeGestion,
          CodeTypeProd: value.type,
          QueryExtendedAtBD: value.external_db,
        })) as { label: string; value: string }[];
        return result;
      },
    } as SearchFormFieldType,
    {
      position: "type:paper",
      key: "famille", // Demander à quoi ça correspond
      label: t("products.search.filters.paper.famille.libelle"),
      placeholder: t("products.search.filters.paper.famille.placeholder"),
      type: "searchselect",
      options: async (query) => {
        const result = (await getSuggestions({
          Famille: query,
          ModeGestion: modeGestion,
          CodeTypeProd: value.type,
          QueryExtendedAtBD: value.external_db,
        })) as { label: string; value: string }[];
        return result;
      },
    } as SearchFormFieldType,
    {
      position: "type:paper",
      key: "sousFamille", // Demander à quoi ça correspond
      label: t("products.search.filters.paper.sousFamille.libelle"),
      placeholder: t("products.search.filters.paper.sousFamille.placeholder"),
      type: "searchselect",
      options: async (query) => {
        if (value.famille) {
          const result = (await getSuggestions({
            SousFamille: value.famille.value,
            ModeGestion: modeGestion,
            CodeTypeProd: value.type,
            QueryExtendedAtBD: value.external_db,
          })) as { label: string; value: string; codeRech: string }[];
          return result;
        } else {
          const result = (await getSuggestions({
            SousFamille: query,
            ModeGestion: modeGestion,
            CodeTypeProd: value.type,
            QueryExtendedAtBD: value.external_db,
          })) as { label: string; value: string }[];
          return result;
        }
      },
    } as SearchFormFieldType,
    {
      position: "type:paper",
      key: "segment",
      label: t("products.search.filters.paper.segment.libelle"), // Demander à quoi ça correspond
      placeholder: t("products.search.filters.paper.segment.placeholder"),
      type: "searchselect",
      options: async (query) => {
        if (value.sousFamille) {
          const result = (await getSuggestions({
            Segment: value.sousFamille.value,
            ModeGestion: modeGestion,
            CodeTypeProd: value.type,
            QueryExtendedAtBD: value.external_db,
          })) as { label: string; value: string; codeRech: string }[];
          return result;
        }
        if (value.famille) {
          const result = (await getSuggestions({
            Segment: value.famille.value,
            ModeGestion: modeGestion,
            CodeTypeProd: value.type,
            QueryExtendedAtBD: value.external_db,
          })) as { label: string; value: string; codeRech: string }[];
          return result;
        } else {
          const result = (await getSuggestions({
            Segment: query,
            ModeGestion: modeGestion,
            CodeTypeProd: value.type,
            QueryExtendedAtBD: value.external_db,
          })) as { label: string; value: string; codeRech: string }[];
          return result;
        }
      },
    } as SearchFormFieldType,
  ];

  const PressField = [
    {
      position: "type:press",
      key: "catCommision",
      label: t("products.search.filters.press.catMultimedia.libelle"),
      placeholder: t("products.search.filters.press.catMultimedia.placeholder"),
      type: "text",
    } as SearchFormFieldType,
    {
      position: "type:press",
      key: "catCommerciale",
      label: t("products.search.filters.press.catCommerciale.libelle"),
      placeholder: t(
        "products.search.filters.press.catCommerciale.placeholder"
      ),
      type: "text",
    } as SearchFormFieldType,
    {
      position: "type:press",
      key: "catMultimedia",
      label: t("products.search.filters.press.catMultimedia.libelle"),
      placeholder: t("products.search.filters.press.catMultimedia.placeholder"),
      type: "text",
    } as SearchFormFieldType,
    {
      position: "type:press",
      key: "typeMultimedia",
      label: t("products.search.filters.press.typeMultimedia.libelle"),
      placeholder: t(
        "products.search.filters.press.typeMultimedia.placeholder"
      ),
      type: "text",
    } as SearchFormFieldType,
    {
      position: "type:press",
      key: "sousFamille",
      label: t("products.search.filters.press.sousFamille.libelle"),
      placeholder: t("products.search.filters.press.sousFamille.placeholder"),
      type: "text",
    } as SearchFormFieldType,
    {
      position: "type:press",
      key: "numParution",
      label: t("products.search.filters.press.dateParu.libelle"),
      type: "date",
    } as SearchFormFieldType,
    {
      position: "type:press",
      key: "classement",
      label: t("products.search.filters.press.classement.libelle"),
      type: "select",
      options: [
        { label: "Quotidien", value: "Quotidien" },
        { label: "Hebdomadaire", value: "Hebdomadaire" },
      ],
    } as SearchFormFieldType,
    {
      position: "type:press",
      key: "etranger",
      label: t("products.search.filters.press.etranger.libelle"),
      type: "boolean",
    } as SearchFormFieldType,
    {
      position: "type:press",
      key: "jamaisTropVieux",
      label: t("products.search.filters.press.jamaisTropVieux.libelle"),
      type: "boolean",
    } as SearchFormFieldType,
    {
      position: "type:press",
      key: "prioritaire",
      label: t("products.search.filters.press.prioritaire.libelle"),
      type: "boolean",
    } as SearchFormFieldType,
    {
      position: "type:press",
      key: "sarra",
      label: t("products.search.filters.press.sarra.libelle"),
      type: "boolean",
    } as SearchFormFieldType,
  ];

  const RecordFields = [
    {
      position: "type:record",
      key: "auteur",
      label: "Interprète",
      type: "text",
      placeholder: t("products.search.filters.record.auteur.placeholder"),
    } as SearchFormFieldType,
    {
      position: "type:record",
      key: "interprete",
      label: t("products.search.filters.record.interprete.libelle"),
      type: "text",
      placeholder: t("products.search.filters.record.auteur.placeholder"),
    } as SearchFormFieldType,
    {
      position: "type:record",
      key: "editeur",
      label: t("products.search.filters.record.editeur.libelle"),
      placeholder: t("products.search.filters.record.editeur.placeholder"),
      type: "searchselect",
      options: async (query) => {
        const result = (await getSuggestions({
          Editeur: query,
          ModeGestion: modeGestion,
          CodeTypeProd: value.type,
          QueryExtendedAtBD: value.external_db,
        })) as { label: string; value: string }[];
        return result;
      },
    } as SearchFormFieldType,
    {
      position: "type:record",
      key: "genre",
      label: t("products.search.filters.record.genre.libelle"),
      placeholder: t("products.search.filters.record.genre.placeholder"),
      type: "searchselect",
      options: async (query) => {
        const result = (await getSuggestions({
          Genre: query,
          ModeGestion: modeGestion,
          CodeTypeProd: value.type,
          QueryExtendedAtBD: value.external_db,
        })) as { label: string; value: string }[];
        return result;
      },
    } as SearchFormFieldType,
    {
      position: "type:record",
      key: "support",
      label: t("products.search.filters.record.support.libelle"),
      placeholder: t("products.search.filters.record.support.placeholder"),
      type: "select",
      options: [
        {
          label: "CD",
          value: "CD",
        },
        {
          label: "VINYL",
          value: "VINYL",
        },
        {
          label: "NUMERIQUE",
          value: "NUMERIQUE",
        },
      ],
    } as SearchFormFieldType,

    {
      position: "type:record",
      key: "dateParutionAprès",
      label: t("products.search.filters.record.datePublicationApres"),
      type: "date",
    } as SearchFormFieldType,
    {
      position: "type:record",
      key: "dateParutionAvant",
      label: t("products.search.filters.record.datePublicationAvant"),
      type: "date",
    } as SearchFormFieldType,
  ];

  const setFieldPosition = (
    typeName: string,
    fields: SearchFormFieldType[]
  ) => {
    const list = fields.map((field, index) => ({
      ...field,
      position: `type:${typeName}`,
    }));
    return list;
  };
  const getFields = () => {
    const final = current
      ?.items!.map((el, index) => {
        switch (el.modeGest) {
          case "L":
            return setFieldPosition(el.codeTypeProd, BookFields);
          case "N":
            return setFieldPosition(el.codeTypeProd, BookFields);
          case "D":
            return setFieldPosition(el.codeTypeProd, RecordFields);
          case "S":
            return setFieldPosition(el.codeTypeProd, PressField);
          default:
            return setFieldPosition(el.codeTypeProd, PaperFields);
        }
      })
      .flat();

    return final;
  };

  getFields();

  return (
    <SearchForm
      className={advanced ? "" : "mt-6"}
      fields={
        !advanced
          ? [
              {
                key: "designation",
                type: "scan",
                label: t("products.search.filters.advanced.titre.libelle"),
                placeholder: t("products.search.placeholder"),
                enableMic: true,
                options: async (query) => {
                  const result = (await getAggregatedSuggestions({
                    Designation: query,
                    QueryExtendedAtBD: value.external_db,
                  })) as { label: string; value: string }[];
                  return result;
                },
                autoFocus: true,
                onClickOption: (e) => {
                  onSearch(e.target.value);
                },
              } as SearchFormFieldType,
              {
                key: "external_db",
                type: "boolean",
                placeholder: t("products.search.filters.advanced.externe"),
              } as SearchFormFieldType,

              {
                key: "dummy",
                position: "advanced",
                type: "boolean",
              } as SearchFormFieldType,
            ]
          : [
              {
                key: "id",
                label: t("products.search.filters.advanced.ean.libelle"),
                type: "scan",
                placeholder: t(
                  "products.search.filters.advanced.ean.placeholder"
                ),
                autoFocus: "scan",
              } as SearchFormFieldType,
              {
                key: "designation",
                label: t(
                  "products.search.filters.advanced.designation.libelle"
                ),
                type: "text",
                placeholder: t(
                  "products.search.filters.advanced.designation.placeholder"
                ),
                enableMic: true,
                options: async (query) => {
                  const result = (await getAggregatedSuggestions({
                    Designation: query,
                    CodeTypeProd: value.type,
                    ...value,
                    QueryExtendedAtBD: value.external_db,
                  })) as { label: string; value: string }[];
                  return result;
                },
                autoFocus: "keyboard",
                onClickOption: (e) => {
                  onSearch(e.target.value);
                },
              } as SearchFormFieldType,
              {
                key: "rayon",
                label: t("products.search.filters.advanced.libRayon.libelle"),
                type: "searchselect",
                placeholder: t(
                  "products.search.filters.advanced.libRayon.placeholder"
                ),
                options: async (query) => {
                  const result = await getSuggestions({
                    Rayon: query,
                    ModeGestion: modeGestion,
                    CodeTypeProd: value.type,
                  });
                  return result;
                },
              } as SearchFormFieldType,
              //{
              //   label: t(
              //     "products.search.filters.advanced.departement.libelle"
              //   ),
              //   key: "departement",
              //   type: "searchselect",
              //   placeholder: t(
              //     "products.search.filters.advanced.departement.placeholder"
              //   ),
              //   options: async (query) => {
              //     const result = await getSuggestions({
              //       Departement: query,
              //       ModeGestion: modeGestion,
              //       CodeTypeProd: value.type,
              //     });
              //     return result;
              //   },
              // } as SearchFormFieldType,
              {
                label: t("Fournisseur"),
                key: "fournisseur",
                type: "searchselect",
                placeholder: t("Fournisseur..."),
                options: async (query) => {
                  const result = await getSuggestions({
                    Fournisseur: query,
                    ModeGestion: modeGestion,
                    CodeTypeProd: value.type,
                    QueryExtendedAtBD: value.external_db,
                  });
                  return result;
                },
              } as SearchFormFieldType,

              {
                key: "disponible",
                type: "boolean",
                placeholder: "Dispo. vente",
              } as SearchFormFieldType,

              {
                key: "external_db",
                type: "boolean",
                placeholder: t("products.search.filters.advanced.externe"),
              } as SearchFormFieldType,
              {
                position: "advanced",
                key: "type",
                type: "group",
                options: getFieldsTab(),
              } as SearchFormFieldType,
              {
                position: "type:all",
                key: "type:all:text",
                type: "custom",
                node: () => <Info>Afficher tous les types de produits.</Info>,
              } as SearchFormFieldType,
            ].concat(getFields() as SearchFormFieldType[])
      }
      value={value}
      onChange={async (filters) => {
        let temp = { ...filters };
        if (temp["id"]) temp["id"] = temp["id"].replace("~", "");
        if (temp["designation"])
          temp["designation"] = temp["designation"].replace("~", "");

        convertISOStringsToShortFormat(temp);

        for (const cle of Object.keys(filters)) {
          if (Array.isArray(filters[cle])) {
            temp[cle] = filters[cle][0];
          }
          if (temp[cle] === undefined) {
            delete temp[cle];
          }
        }
        //To deal with Famille/SousFamille/Segment sugg
        if (filters.segment !== value.segment) {
          if (Array.isArray(filters.segment) && filters.segment.length === 0) {
            onChange({ ...temp, segment: undefined });
            return;
          } else {
            const res = await handleFamilySugg("Segment", filters.segment);
            onChange({ ...temp, ...res });
            return;
          }
        }

        if (filters.sousFamille !== value.sousFamille) {
          if (
            Array.isArray(filters.sousFamille) &&
            filters.sousFamille.length === 0
          ) {
            onChange({ ...temp, sousFamille: undefined, segment: undefined });
            return;
          } else {
            const res = await handleFamilySugg(
              "SousFamille",
              filters.sousFamille
            );
            onChange({ ...temp, ...res });
            return;
          }
        }

        if (filters.famille !== value.famille) {
          if (Array.isArray(filters.famille) && filters.famille.length === 0) {
            onChange({
              ...temp,
              famille: undefined,
              sousFamille: undefined,
              segment: undefined,
            });
            return;
          } else {
            const res = await handleFamilySugg("Famille", filters.famille);
            onChange({ ...temp, ...res });
            return;
          }
        }

        if (temp.type !== "all") {
          const newModeGest = current?.items?.find(
            (el) => el.codeTypeProd === temp.type
          )?.modeGest;
          if (newModeGest) setModeGestion(newModeGest);
        } else {
          setModeGestion("all");
        }

        onChange(temp);

        // if (
        //   value.designation &&
        //   filters.designation.length - value.designation.length > 1
        // ) {
        //   onSearch(filters.designation);
        // }
      }}
      onSearch={() => {
        onSearch();
      }}
    />
  );
};
