import { Page } from "@atoms/layout/page";
import { TableGridSwitch } from "@molecules/table/table-grid-switch";
import { Column } from "@molecules/table/table";
import { useState } from "react";
import { Base, Info } from "@atoms/text";
import { SupplierOrderLine } from "@features/supplier/types";
import { useSuppliers } from "@features/supplier/state/use-suppliers";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@features/routes";
import { BookRender } from "@atoms/book";

export const SupplierOrderLinePage = () => {
  const [orderLines, setOrderLines] = useState<SupplierOrderLine[]>([]);

  const { selectedFournCde, getFournOrderLines } = useSuppliers();
  const navigate = useNavigate();

  // --> Liste des colonnes à compléter
  const orderColumns: Column<SupplierOrderLine>[] = [
    {
      title: "Article",
      id: "",
      render: (p) => (
        <div
          className="flex flex-row items-center space-x-4 cursor-pointer"
          onClick={() =>
            navigate(
              ROUTES.Product.replace(/:ean/, p.ean13).replace(
                /:type/,
                p.tooltip.modeGest
              )
            )
          }
        >
          <div className="w-10 print:hidden">
            <BookRender src={p.imageURL} productType={p.tooltip.modeGest} />
          </div>
          <div className="flex flex-col">
            <Base
              className="block overflow-hidden whitespace-nowrap text-ellipsis print:whitespace-normal max-w-xs"
              data-tooltip={p.desig}
            >
              {p.desig}
            </Base>
            <Info>{p.ean13}</Info>
          </div>
        </div>
      ),
    },

    {
      title: "PU TTC",
      id: "raisSocial",
      render: (p) => <Base>{p.prixBase}</Base>,
    },

    {
      title: "Opé.",
      id: "codeOpe",
      render: (p) => <Base>{p.codeOpe}</Base>,
    },
    // {
    //   title: "Panier",
    //   id: "panie",
    //   render: (p) => <Base>{p.panie}</Base>,
    // }, --> Demandera peut-être à être rajouté ??
    {
      title: "Quantité",
      id: "qte",
      render: (p) => <Base>{p.qte}</Base>,
    },
  ];

  return (
    <Page>
      <>
        <TableGridSwitch
          name="cdeFournLigne"
          data={orderLines}
          //onChangeMode={(v) => setGridMode({ activated: v })}
          //gridMode={gridMode.activated}
          tableColumns={orderColumns}
          onRequestData={async () => {
            const res = await getFournOrderLines(selectedFournCde.numCde);
            if (res) setOrderLines(res);
            else setOrderLines([]);
          }}
          renderGrid={() => <> </>}
          gridMode={false}
        />
      </>
    </Page>
  );
};
